/* global gtag */
import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";
import './App.css';
import '../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, XAxis, YAxis, DiscreteColorLegend } from 'react-vis';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import SearchBar from './SearchBar';
import TopList from './TopList';

const defaultData = [
  {x: +(new Date('2000-01-01')), y: 8},
];


class App extends Component {
  constructor() {
    super();
    const searchParams = this.getQueryParams();
    this.dataset = {};
    this.monthsAxis = [];
    this.state = {
      loading: false,
      plottedTermList: [],
      animate: searchParams.get('animate') || false,
      showYears: searchParams.get('years') || true 
    };
  }
  getQueryParams( ){
    return new URL(window.location.href).searchParams
  }
  async componentDidMount(){
    const hasTerms = this.getQueryParams().get('terms');
    if(hasTerms){
      for(const term of hasTerms.split(',')) {
        await this.fetchTermData(term);
      }
    }
  }
  componentDidUpdate(prevProps,prevState){
    const newTerms = this.state.plottedTermList.join(',');
    if(prevState.plottedTermList.join(',') !== newTerms ){
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, null, '?terms='+newTerms);
    }
  }
  groupByYear(monthlyPayload) {
    const finalPayload  = {
      headers: [],
      data: []
    };
    let prevYear = 0;
    let prevYearIdx = -1;
    monthlyPayload.headers.forEach((period,monthIdx) => {
      const currYear = new Date(period).getFullYear();
      if(currYear !== prevYear){
        prevYear = currYear;
        prevYearIdx++;
        finalPayload.headers.push(period);
        finalPayload.data[prevYearIdx] = 0;
      } 
      finalPayload.data[prevYearIdx] += monthlyPayload.data[monthIdx]; 
    });
    return finalPayload;
  }
  async fetchTermData(term) {
    this.setState({ loading: true });
    try {
      let apiData = await(await fetch(`https://rest.splitcloud-app.com/rapsum/trends?term=${term}`)).json();
      if(this.state.showYears) {
        apiData = this.groupByYear(apiData);
      }
      this.monthsAxis = apiData.headers;
      if(apiData.data.length === 0) {
        return this.setState({
          loading: false,
        })
      }
      if(this.state.animate) {
        this.dataset[term] = [];
        // actually populate with data to interpolate with animation
        apiData.data.forEach((el,idx) => {
          setTimeout(() => {
            this.dataset[term][idx] = el;
            this.dataset[term][idx+1] = el;
            this.forceUpdate();
          },idx*150);
        });
      } else { 
         this.dataset[term] = new Array(apiData.data.length).fill(0);
         setTimeout(() => {
          this.dataset[term] = apiData.data;
          this.forceUpdate();
         },500);
      }
      this.setState({
        loading: false,
        plottedTermList: [...this.state.plottedTermList,term],
      });
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
      });
    }
  }
  trackShare(platform){
    const term_list = this.state.plottedTermList.join(',')
    gtag('event', 'share_term', {term_query: term_list, platform });
  }
  onPlotTerm = term => {
    gtag('event', 'plot_term', {term_query: term});
    this.fetchTermData(term);
  }
  onItemClick = item => {
    this.setState({
      plottedTermList: this.state.plottedTermList.filter(i => item.indexOf(i) === -1 )
    })
  }
  onToggleYearsMonths = () => {
    this.setState({
      showYears: !this.state.showYears
    })
  }
  onClearAll = () => {
    this.setState({
      plottedTermList:[]
    })
  }
  getLegendWithTotal = (term) => {
    if(!this.dataset[term]) return term;
    const totOccur = this.dataset[term].reduce((acc,curr) => acc +=parseInt(curr), 0);
    return `${term} (${totOccur} times)`;
  }
  render() {
    const startTs = this.monthsAxis[0] || new Date('2000-01');
    const endTs = this.monthsAxis[this.monthsAxis.length -1] || new Date('2022-01');
    let maxYDomainValue = 0;
    const isSmallScreen = window.innerWidth <= 767;
    const linesData = this.state.plottedTermList.length ? this.state.plottedTermList.map((term) => {
      if (term in this.dataset) {
        return this.dataset[term].map((currCol,colIndex) => {
          if(currCol > maxYDomainValue) maxYDomainValue = currCol;
          return {x: this.monthsAxis[colIndex], y: currCol}
        });
      } else {
        return defaultData;
      }
    }): [defaultData];
    maxYDomainValue = Math.max(maxYDomainValue, 16);
    const chartWidth = Math.min(window.innerWidth - 50, 1100);
    const chartHeight = Math.min(chartWidth * (9/16),500);
    const shareUrlUpdated = `https://trends.rapsum.com/?terms=${this.state.plottedTermList.join(',')}`;
    return (
      <div className="App">
        <SearchBar onSubmit={this.onPlotTerm} />
        <div style={{position:'relative',width:chartWidth,margin:'0px auto'}}>
          {this.state.loading && (<div className="loaderContainer"><img src='./loader.gif' /></div>) }
          <XYPlot 
            xDomain={[startTs, endTs]}
            yDomain={[0,maxYDomainValue]}
            xType="time"
            height={chartHeight} width={chartWidth}>{
              linesData.map((lineData,idx) => {
                return <LineSeries animation key={idx} strokeWidth={isSmallScreen ? 2: 4} data={lineData} />
              })
              }
            <XAxis style={{
              line: {stroke: '#fff'},
              ticks: {stroke: '#fff'},
              text: {stroke: 'none', fill: '#fff', fontWeight: 600, fontSize: isSmallScreen ? 10: 15}
            }} tickSizeOuter={0} />
            <YAxis style={{
              line: {stroke: '#fff'},
              ticks: {stroke: '#fff'},
              text: {stroke: 'none', fill: '#fff', fontWeight: 600, fontSize: isSmallScreen ? 12: 15}
            }} tickSize={0}/>
          </XYPlot>
          <div style={{textAlign:'center'}}><input type="button" onClick={this.onToggleYearsMonths} value={this.state.showYears? 'Yearly': 'Monthly'} className="clearAll" /></div>
          {this.state.plottedTermList.length ? <div className="legend">
            <DiscreteColorLegend items={this.state.plottedTermList.map(this.getLegendWithTotal)}  onItemClick={this.onItemClick} />
            <div style={{textAlign:'center'}}><input type="button" onClick={this.onClearAll} value="Clear All" className="clearAll" /></div>
          </div>: ''}
        </div>
        <div className="social-share" >
          <h3>Share your trends:</h3>
          <FacebookShareButton beforeOnClick={this.trackShare.bind(this,'facebook')} url={shareUrlUpdated}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <WhatsappShareButton beforeOnClick={this.trackShare.bind(this,'whatsapp')} url={shareUrlUpdated}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
          <TwitterShareButton  beforeOnClick={this.trackShare.bind(this,'twitter')} url={shareUrlUpdated}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>  
          <EmailShareButton beforeOnClick={this.trackShare.bind(this,'email')} url={shareUrlUpdated} subject="Check this website out">
            <EmailIcon size={32} round />
          </EmailShareButton>
          <TelegramShareButton beforeOnClick={this.trackShare.bind(this,'telegram')} url={shareUrlUpdated}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>
        <TopList onResultClick={this.onPlotTerm}/>
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    );
  }
}

export default App;