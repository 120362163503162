import React, { Component } from 'react';

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      currTermInput :'',
    };
  }
  onTermChange = e => {
    e.preventDefault();
    this.setState({currTermInput: e.target.value.toLowerCase()})
  };
  onSubmit = e => {
    e.preventDefault();
    if(!this.state.currTermInput) return;
    const lastValue = this.state.currTermInput;
    this.props.onSubmit(lastValue);
    this.setState({currTermInput: ''});
  }
  render() {

    return (
        <form className="searchbar">
          <img src='./rapsumlogo.jpeg' className="bgLogo" />
          <h1>Rapsum Trends</h1>
          <h2>Discover the most mentioned words in the Billboard HOT100 songs</h2>
          <input type="text" value={this.state.currTermInput} onChange={this.onTermChange} placeholder="Try a brand eg. Gucci" />
          <input type="submit" onClick={this.onSubmit} value="Go!" />
        </form>
    );
  }
}

export default SearchBar;