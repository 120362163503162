import React, { Component } from 'react';
import explicitList  from './constants/explicitList';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      list: [],
    };
  }
  async componentDidMount() {
    await this.fetchTopList();
  }
  async fetchTopList() {
    try {
      let apiData = await(await fetch(`https://rest.splitcloud-app.com/rapsum/trends?chart=popular`)).json();
      console.log(apiData);
      this.setState({
        loading: false,
        list: apiData.cachedRapsumTopList || [],
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  }
  onItemClick = item => {
    this.props.onResultClick && this.props.onResultClick(item);
  }
  getLegendWithTotal = (item) => {
    const totOccur = item.slice(1).reduce((acc,curr) => acc +=parseInt(curr), 0);
    return <><span>{explicitList[item[0]] || item[0]}</span><small style={{color:'#ccc'}}> - {(totOccur/1000).toFixed(2)}k</small></>;
  }
  render() {
    return (
      <div className="container">
        <h3>TOP 50 Words of all time </h3>
        {this.state.loading && <img src='./loader.gif' height='50px' alt='Loading...' />}
        <ul className="topListGrid">
        {this.state.list.map(wItem => <li><a href='#' onClick={() => this.onItemClick(wItem[0])} >{this.getLegendWithTotal(wItem)}</a></li>)}
        </ul>
      </div>
    );
  }
}

export default App;