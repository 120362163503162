const EXPLICIT_WORD_LIST = {
  bitch: 'bi*ch',
  bitches: 'bi*ches',
  nigga: 'ni**a',
  niggas: 'ni**as',
  pussy: 'pu**y',
  whores: 'wh*res',
  fuck: 'f*ck',
  shit: 'sh*t',
  dick: 'd*ck',
  '4r5e': 'a**e',
  '5h1t': 's**t',
  '5hit': 's**t',
  a55: 'a*s',
  anal: 'a*al',
  anus: 'a*us',
  ar5e: 'a**e',
  arrse: 'a**e',
  arse: 'a**e',
  ass: 'a*s',
  'ass-fucker': 'a*s f**er',
  asses: 'a**es',
  assfucker: 'a***ers',
  assfukka: 'a***ers',
  asshole: 'a***le',
  assholes: 'a***les',
  asswhole: 'a***le',
  a_s_s: 'a*s',
  'b!tch': 'bi*ch',
  b00bs: 'b**bs',
  b17ch: 'b**ch',
  b1tch: 'b**ch',
  ballbag: 'ballb*g',
  balls: 'b*lls',
  ballsack: 'balls*ck',
  bastard: 'b**tard',
  'bi+ch': 'b**ch',
  biatch: 'b**ch',
  bitcher: 'b**cher',
  bitchers: 'b**chers',
  bitchin: 'b**chin',
  bitching: 'b**ching',
  blowjob: 'bl*wjob',
  blowjobs: 'bl*wjobs',
  bollock: 'b*llock',
  bollok: 'b*llok',
  boner: 'b*ner',
  boob: 'b**b',
  boobs: 'b**bs',
  booobs: 'b**bs',
  boooobs: 'b**bs',
  booooobs: 'b**bs',
  booooooobs: 'b**bs',
  breasts: 'br*asts',
  bum: 'b*m',
  butt: 'b*tt',
  butthole: 'b*tthole',
  buttmuch: 'b*ttmuch',
  buttplug: 'b*ttplug',
  c0ck: 'c*ck',
  c0cksucker: 'c*cksucker',
  cl1t: 'cl*t',
  clit: 'cl*t',
  clitoris: 'cl*toris',
  clits: 'cl*ts',
  cock: 'c*ck',
  cockface: 'c*ckface',
  cockhead: 'c*ckhead',
  cockmunch: 'c*ckmunch',
  cockmuncher: 'c*ckmuncher',
  cocks: 'c*cks',
  cocksuck: 'c*cksuck',
  cocksucked: 'c*cksucked',
  cocksucker: 'c*cksucker',
  cocksucking: 'c*cksucking',
  cocksucks: 'c*cksucks',
  cocksuka: 'c*cksuka',
  cocksukka: 'c*cksukka',
  cok: 'c*ck',
  cokmuncher: 'c*ckmuncher',
  coksucka: 'c*cksucka',
  coon: 'c*on',
  crap: 'cr*p',
  cum: 'c*m',
  cummer: 'c*mmer',
  cumming: 'c*mming',
  cums: 'c*ms',
  cumshot: 'c*mshot',
  cunilingus: 'c*nilingus',
  cunillingus: 'c*nillingus',
  cunnilingus: 'c*nnilingus',
  cunt: 'c*nt',
  cuntlick: 'c*ntlick',
  cuntlicker: 'c*ntlicker',
  cuntlicking: 'c*ntlicking',
  cunts: 'c*nts',
  d1ck: 'd*ck',
  dickhead: 'd*ckhead',
  dildo: 'd*ldo',
  dildos: 'd*ldos',
  dink: 'd*nk',
  dinks: 'd*nks',
  doggin: 'censor*d',
  dogging: 'censor*d',
  duche: 'censor*d',
  dyke: 'd*ke',
  ejaculate: 'e*aculate',
  ejaculated: 'e*aculated',
  ejaculates: 'e*aculates',
  ejaculating: 'e*aculating',
  ejaculatings: 'e*aculatings',
  ejaculation: 'e*aculation',
  ejakulate: 'e*aculate',
  'f u c k': 'f*ck',
  'f u c k e r': 'f*ckr',
  f4nny: 'f*nny',
  fag: 'f*g',
  fagging: 'f*ging',
  faggitt: 'f*gitt',
  faggot: 'f*ggot',
  faggs: 'f*ggs',
  fagot: 'f*got',
  fagots: 'f*gots',
  fags: 'f*gs',
  fanny: 'f*nny',
  fannyflaps: 'f*nnyflaps',
  fannyfucker: 'f*nnyfucker',
  fatass: 'f*tass',
  fcuk: 'f*ck',
  fcuker: 'f*cker',
  fcuking: 'f*cking',
  feck: 'f*ck',
  fellate: 'f*llate',
  fellatio: 'f*llatio',
  fingerfuck: 'fingerf*ck',
  fingerfucked: 'fingerf*cked',
  fingerfucker: 'fingerf*cker',
  fingerfuckers: 'fingerf*ckers',
  fingerfucking: 'fingerf*cking',
  fingerfucks: 'fingerf*cks',
  fistfuck: 'fistf*ck',
  fistfucked: 'fistf*cked',
  fistfucker: 'fistf*cker',
  fistfuckers: 'fistf*ckers',
  fistfucking: 'fistf*cking',
  fistfuckings: 'fistf*ckings',
  fistfucks: 'fistf*ck',
  fucked: 'f*cked',
  fucker: 'f*cker',
  fuckers: 'f*ckers',
  fuckhead: 'f*ckhead',
  fuckin: 'f*ckin',
  fucking: 'f*cking',
  fuckings: 'f*ckings',
  fuckme: 'f*ckme',
  fucks: 'f*cks',
  fuker: 'f*ker',
  fukker: 'f*kker',
  gangbang: 'gang*ang',
  gangbanged: 'gang*anged',
  gangbangs: 'gang*angs',
  goddamn: 'godd*mn',
  goddamned: 'godd*mned',
  homo: 'h*mo',
  horny: 'h*rny',
  mof0: 'm*fo',
  mofo: 'm*fo',
  mothafuck: 'mothaf*ck',
  mothafucka: 'mothaf*cka',
  mothafuckas: 'mothaf*ckas',
  mothafuckaz: 'mothaf*ckas',
  mothafucked: 'mothaf*cked',
  mothafucker: 'mothaf*cker',
  mothafuckers: 'mothaf*ckers',
  mothafuckin: 'mothaf*ckin',
  mothafucking: 'mothaf*cking',
  mothafucks: 'motherf*ck',
  motherfuck: 'motherf*ck',
  motherfucked: 'motherf*cked',
  motherfucker: 'mothaf*cker',
  motherfuckers: 'mothaf*ckers',
  motherfuckin: 'motherf*cking',
  motherfucking: 'motherf*cking',
  motherfuckings: 'motherf*ckings',
  motherfucks: 'motherf*cking',
  niggah: 'n*ggah',
  niggaz: 'n*ggaz',
  nigger: 'n*gger',
  niggers: 'n*ggers',
  p0rn: 'p*rn',
  penis: 'p*nis',
  piss: 'p*ss',
  pissed: 'p*ssed',
  pisses: 'p*sses',
  pissin: 'p*ssin',
  pissing: 'p*ssing',
  pussies: 'p*ssies',
  pussys: 'p*ssys',
  sex: 's*x',
  sh1t: 'sh*t',
  shag: 'sh*t',
  shitdick: 'sh*tdick',
  shite: 'sh*t',
  shited: 'sh*ted',
  shitey: 'censor*d',
  shitfuck: 'sh*tfuck',
  shiting: 'censor*d',
  shitings: 'censor*d',
  shits: 'sh*ts',
  shitted: 'censor*d',
  shitter: 'sh*tter',
  shitters: 'censor*d',
  shitting: 'censor*d',
  shittings: 'censor*d',
  shitty: 'sh*tty',
  skank: 's*ank',
  slut: 's*lut',
  sluts: 's*luts',
  snatch: 'sn*tch',
  tit: 't*t',
  tits: 't*ts',
  titt: 't*ts',
  titties: 't*tties',
  turd: 't*rd',
  tw4t: 'tw*t',
  twat: 'tw*t',
  twatty: 'tw*tty',
  vagina: 'v*gina',
  wank: 'w*nk',
  whore: 'who*re',
  xxx: 'x*x',
};
export default EXPLICIT_WORD_LIST;